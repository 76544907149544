import type {
  ActionFunction,
  LoaderArgs,
  LoaderFunction,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import * as React from "react";
import { identify, track } from "~/analytics.server";
import { BrandedBanner, Input } from "~/components";
import { verifyLogin } from "~/models/user.server";
import { createUserSession, getUserId } from "~/session.server";
import { isEmail, safeRedirect } from "~/utils/utils";

export const loader: LoaderFunction = async ({ request }) => {
  const userId = await getUserId(request);
  if (userId) return redirect("/");
  return json({});
};

interface ActionData {
  errors?: {
    email?: string;
    password?: string;
  };
}

export const action: ActionFunction = async ({ request }: LoaderArgs) => {
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");
  const redirectUrlParams = formData.get("redirectTo");
  const redirectTo = safeRedirect(redirectUrlParams, "/in/certs");
  const remember = formData.get("remember");

  if (!isEmail(email)) {
    return json({ errors: { email: "Email is invalid" } }, { status: 400 });
  }

  if (typeof password !== "string" || password.length === 0) {
    return json(
      { errors: { password: "Password is required" } },
      { status: 400 }
    );
  }

  if (password.length < 8) {
    return json(
      { errors: { password: "Password is too short" } },
      { status: 400 }
    );
  }

  const user = await verifyLogin(email, password);

  if (!user) {
    return json(
      {
        errors: { password: "Invalid email or password. Have you signed up?" },
      },
      { status: 400 }
    );
  }

  const cookie = await createUserSession({
    request,
    userId: user.id,
    remember: remember === "on" ? true : false,
  });

  identify(request)(user.id, {
    email: user.email,
    firstName: user.profile?.firstName || undefined,
    lastName: user.profile?.lastName || undefined,
    name: user.profile
      ? `${user.profile.firstName} ${user.profile.lastName}`
      : undefined,
    avatar: user.profile?.imageUrl,
    birthday: user.profile?.dateOfBirth?.toISOString() || undefined,
  });
  track(user.id, "LOGIN_SUCCESSFUL");

  return redirect(redirectTo, {
    headers: {
      "Set-Cookie": cookie,
    },
  });
};

export const meta: MetaFunction = () => {
  return {
    title: "Login",
  };
};

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") || "/enter";
  const emailParam = searchParams.get("email") ?? undefined;
  const actionData = useActionData() as ActionData;
  const emailRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);
  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mb-4">
          <BrandedBanner />
        </div>
        <div className="m-4 rounded bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form className="space-y-4" method="post" noValidate>
            <Input
              id="email"
              type="email"
              label="Email"
              ref={emailRef}
              error={actionData?.errors?.email}
              autocomplete="email"
              defaultValue={emailParam}
            />

            <Input
              id="password"
              type="password"
              label="Password"
              ref={passwordRef}
              error={actionData?.errors?.password}
              autocomplete="current-password"
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember"
                  data-cy="remember"
                  name="remember"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor="remember"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link
                  data-cy="nav-forgotPassword"
                  className="text-blue-500 underline"
                  to={{
                    pathname: "/forgot",
                    search: searchParams.toString(),
                  }}
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <input type="hidden" name="redirectTo" value={redirectTo} />

            <div>
              <button
                type="submit"
                data-cy="submit-login"
                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Login
              </button>
            </div>
            <div className="text-center text-sm text-gray-500">
              Don't have an account?{" "}
              <Link
                data-cy="nav-signup"
                className="text-blue-500 underline"
                to={{
                  pathname: "/signup",
                  search: searchParams.toString(),
                }}
              >
                Sign up
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
